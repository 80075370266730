@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  background: #f0f1f4;
}

main {
  width: 80%;
  margin: auto;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.fade-in {
  transform: translateY(150px);
  opacity: 0;
  transition: transform 0.6s ease-out, opacity 0.6s ease-out;
}

.fade-in.in-view {
  transform: translateY(0);
  opacity: 1;
}


/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {}

/* Mobile */
@media (max-width: 768px) {
  html,
  body {
    overflow-x: hidden;
  }
  main{
    width: 97%;
    box-sizing: border-box;
  }
  body{
    font-size: 15px;
  }
  .hide-on-phone{
    display: none;
  }

}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {}