footer {
  position: relative;
  bottom: 0;
  color: #000;
  height: fit-content;
}

footer a {
  text-decoration: none;
  color: #686976;
  transition: color 0.1s ease-out;

  &:hover {
    color: #a01aff;
  }
}

.Mid {
  padding: 100px 17px 60px 7px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  background: #f0f1f4;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
}


.Mid>div {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
  align-items: center;
}

.Mid h3 {
  padding: 10px 0;
  text-transform: uppercase;
}

.more-services-f {
  display: flex;
  flex-direction: column;
  gap: 15px;
  text-align: start;
}

.more-services-f>div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.more-services-f svg:hover path {
  stroke: none;
  fill: #a01aff;
}

.more-services-f a:last-child svg {
  margin-left: -4px;
}

.more-services-f a:last-child svg:hover path:nth-child(2) {
  fill: #f0f1f4;
}

.more-services-f .links {
  display: flex;
  gap: 6px;
}

.bottom {
  border-top: 1px solid #e7e7e9;
  background: #f0f1f4;
  padding: 50px 30px;
  gap: 10px;
}

footer pre{
  font-family: "Montserrat", sans-serif;
}

footer .logo{
  justify-content: start;
}

/* Mobile */
@media (max-width: 768px) {
  .Mid {
    padding: 10px 5px 30px 5px;
    display: grid;
    grid-template-columns: 1fr;
  }

  footer .logo{
    display: none;
  }
  .Mid>div {
    width: 100%;
    flex-direction: column;
    gap: 8px;
    min-width: 150px;
  }

  .bottom {
    padding: 30px 10px;
    gap: 10px;
    font-size: 0.8em;
    display: flex;
    flex-direction: column;
  }
  .Mid >div >div{
    width: 70%;
  }
}