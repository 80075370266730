.home {
  height: fit-content;
  min-height: 100vh;
  width: 100%;
  max-width: 1500px;
  margin: auto;
  padding-top: 70px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.home>div {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 300px;
  padding: 60px 14px;
  height: calc(100vh - 100px);
}

.left {
  text-align: start;
  flex-direction: column;
  gap: 20px;
}

.left a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

.left p {
  letter-spacing: 1px;
  line-height: 25px;
  color: #686976;
}

.big-h {
  font-size: 4rem;
  font-weight: 900;
}

.left h1 span:first-child {
  color: #6a0dad;
}

.left h1 span:nth-child(2) {
  color: #808080;
}

.left h1 span:last-child {
  color: #6a0dad;
}

.left p {
  font-size: 1.2rem;
}

.right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
}

.right>div {
  height: 80%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 25px;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  pointer-events: none;
  box-shadow:
    inset 0 80px 40px -10px #f0f1f4,
    inset 0 -80px 40px -10px #f0f1f4;
}

.right>div::-webkit-scrollbar {
  display: none;
}

.item {
  min-height: 120px;
  z-index: -1;
  user-select: none;
  padding: 10px 20px;
  font-weight: 800;
  border-radius: 14px;
  box-shadow: -2px -2px 3px 0 rgba(255, 255, 255, .8),
    2px 2px 3px 0 rgba(0, 0, 0, .1);
}

.first {
  padding: 20px 10px 20px 30px;
}

.second {
  padding: 20px 30px 20px 10px;
}


.tool-h {
  font-size: 3rem;
  font-weight: 900;
  padding: 0 24px;
}

.heading-search {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.heading-search input,
.heading-search input::placeholder {
  color: #000;
  font-weight: 500;
}

.contact-btn {
  margin: 0px auto 30px auto;
  width: 90%;
  display: flex;
}

.contact-btn a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.1rem;
  text-align: center;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
  outline: none;
  border: 0;
  background: #6a0dad;
  color: #fff;
  box-shadow: 0 4px 10px 0 #6a0dad52, 0 6px 15px 0 #a01aff4e;

  &:active {
    box-shadow: inset 0 4px 10px 0 #6a0dad52,inset 0 6px 15px 0 #a01aff4e;

  }
}


/* Small Laptop, Tablets */
@media (max-width: 1024px) and (min-width: 768px) {}

/* Mobile */
@media (max-width: 768px) {

  .left p {
    font-size: 0.8rem;
  }
  .right{
    margin-bottom: 50px;
  }
  .left{
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .first,
  .second {
    padding: 0;
  }

  .big-h {
    font-size: 3rem;
  }

  .right.mobile {
    display: flex;
    flex-direction: column;
    min-height: 280px;
    height: fit-content;
    max-height: 100px !important;
    padding: 0 !important;
    gap: 0px !important;
    min-width: 100px;
  }

  .right.mobile>div {
    width: 95%;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 10px 0 !important;
    height: auto;
    box-shadow:
      inset 80px 0 40px -10px #f0f1f4,
      inset -80px 0 40px -10px #f0f1f4;
  }

  .right.mobile .item {
    min-width: 200px;
    height: 100px;
  }

  .left {
    min-height: fit-content;
    padding-bottom: 0 !important;
    box-sizing: border-box;
    width: 85%;
    padding: 0 10px;
    max-height: 550px;
  }
}


/* Small obile */
@media (max-width: 400px) {
  .left {
    max-width: 75%;
  }
}

/* Mobile Landscape */
@media (orientation: landscape) and (max-width: 768px) {}