.header {
    background-color: #f0f1f4;
    color: #000;
    padding: 10px 20px;
    position: fixed;
    width: 100%;
    left: 0;
    height: 100px;
    border-bottom: 1px solid #e7e7e9;
    z-index: 20;
    box-sizing: border-box;
  }

  nav{
    margin: auto;
    width: 80%;
    text-align: start;
    align-items: start;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f0f1f4;
  }
  
  .nav-link {
    color: #000;
    text-decoration: none;
    margin: 0 15px;
    transition: color 0.3s ease;
  }
  
  .nav-link:hover {
    color: #a01aff;
  }
  
  .logo {
    display: flex;
    justify-content: center;
    text-align: center;
}

.logo a {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

#favicon {
    width: 44px;
    margin-bottom: 6px;
}

.logo img {
    width: 170px;
}

/* Mobile */
@media (max-width: 768px) {
  header{
    padding: 10px 2px !important;
  }
  nav{
    width: 95%;
  }
}
/* Not Mobile */
@media (min-width: 768px) {
  .hide-on-laptop{
    display: none;
  }
}